import { FC, useState, useMemo, useEffect, SyntheticEvent } from "react";
import { Paper } from "@mui/material";
import { useLocation, useParams, Outlet } from "react-router-dom";
import { PageToolbar, CustomTabPanel, TabsSection } from "@packages/theme-mui-v5";
import { WithPermissions, useGetSchemaByIdOrKey } from "@packages/service-api";
import SchemaFieldsTab from "./tabs/fields/SchemaFieldsTab";
import SchemaLayoutTab from "./tabs/layout/SchemaLayoutTab";
import SchemaPermissionsTab from "./tabs/permissions/SchemaPermissionsTab";
import { Application } from "@nutrien-operations/config";
import { ItemsTab } from "./tabs/items/ItemsTab";
import { useInternalNavigate } from "../../../components/InternalNavigationProvider";

const schemaTabs = ["Fields", "Layout", "Permissions", "Items"];

type TabName = "fields" | "layout" | "permissions" | "items";

type Props = {
  appConfig: Application;
};

const ViewSchema: FC<Props> = ({ appConfig }) => {
  const location = useLocation();
  const navigate = useInternalNavigate();

  const { idOrKey, tab: tabId } = useParams<{ idOrKey: string; tab: TabName }>();

  const initTab = useMemo(() => {
    return tabId ? schemaTabs.findIndex((data) => data.toLowerCase() === tabId) : 0;
  }, [schemaTabs, tabId]);

  const { schema, loading } = useGetSchemaByIdOrKey(idOrKey);

  const [tab, setTab] = useState<number>(initTab);

  useEffect(() => {
    if (!schema || tab === undefined) {
      return;
    }

    const tabPathPart = `/${schemaTabs[tab].toLowerCase()}`;
    const nextPath = `/schemas/${schema.schemaId}${tabPathPart}`;

    if (location.pathname !== nextPath) {
      navigate(nextPath);
    }
  }, [schema, tab]);

  const onTabChange = (_event: SyntheticEvent, nextTab: number) => {
    setTab(nextTab);
  };

  return (
    <WithPermissions
      applicationId={appConfig.applicationId}
      permissions={appConfig?.permissions?.configureApplication}
    >
      <>
        <PageToolbar
          goBack={() => navigate("/schemas")}
          loading={loading}
          title={schema?.schemaName}
        />
        <Paper>
          <TabsSection tabState={tab} tabs={schemaTabs} handleTabChange={onTabChange} />
          <CustomTabPanel value={0} index={tab}>
            <SchemaFieldsTab schemaIdOrKey={idOrKey} />
          </CustomTabPanel>
          <CustomTabPanel value={1} index={tab}>
            <SchemaLayoutTab schemaIdOrKey={idOrKey} />
          </CustomTabPanel>
          <CustomTabPanel value={2} index={tab}>
            <SchemaPermissionsTab schemaIdOrKey={idOrKey} />
          </CustomTabPanel>
          <CustomTabPanel value={3} index={tab}>
            <ItemsTab schemaIdOrKey={idOrKey} />
          </CustomTabPanel>
        </Paper>
        <Outlet />
      </>
    </WithPermissions>
  );
};

export default ViewSchema;
