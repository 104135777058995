import { FC } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";

import { SchemasList } from "./pages/schemas/SchemasList";
import AddEditSchema from "./drawers/AddEditSchema";
import AddEditField from "./drawers/AddEditField";

import { ViewSchema } from "./pages/schemas/ViewSchema";
import DeleteField from "./drawers/DeleteField";
import DeleteSchema from "./drawers/DeleteSchema";
import AddEditLayoutGridItem from "./drawers/AddEditLayoutGridItem";
import AddPermission from "./drawers/AddPermission";
import DeletePermission from "./drawers/DeletePermission";
import DeleteLayoutItemModal from "./pages/schemas/ViewSchema/tabs/layout/grid/modal/DeleteLayoutItemModal";
import { Application } from "@nutrien-operations/config";
import DeleteItem from "./drawers/DeleteItem";
import AddEditItem from "./drawers/AddEditItem";

type Props = {
  appConfig: Application;
};

const SchemaAdminRoutes: FC<Props> = ({ appConfig }) => {
  return (
    <>
      <Routes>
        <Route path={"schemas"} element={<SchemasList showApplicationSelection />}>
          <Route path="new" element={<AddEditSchema />} />
          <Route path=":idOrKey/edit" element={<AddEditSchema />} />
          <Route path=":idOrKey/delete" element={<DeleteSchema />} />
        </Route>

        <Route path={"schemas/:idOrKey/:tab"} element={<ViewSchema appConfig={appConfig} />}>
          <Route path="new" element={<AddEditField />} />

          <Route path=":fieldKey/edit" element={<AddEditField />} />
          <Route path=":fieldKey/clone" element={<AddEditField isClone />} />
          <Route path=":fieldKey/delete" element={<DeleteField />} />
        </Route>

        {/* Routes for Permissions tab */}
        <Route path={"schemas/:idOrKey/:tab"} element={<ViewSchema appConfig={appConfig} />}>
          <Route path=":actionType/new" element={<AddPermission />} />
          <Route path=":actionType/:permissionTypeId/delete" element={<DeletePermission />} />
        </Route>

        {/* Routes for Layout tab */}
        <Route path={"schemas/:idOrKey/:tab"} element={<ViewSchema appConfig={appConfig} />}>
          <Route path="grid/:itemPathKey/:action" element={<AddEditLayoutGridItem />} />
          <Route path="grid/:itemPathKey/delete" element={<DeleteLayoutItemModal />} />
        </Route>

        {/* Routes for Layout tab */}
        <Route path={"schemas/:idOrKey/:tab"} element={<ViewSchema appConfig={appConfig} />}>
          <Route path="new" element={<AddEditItem />} />
          <Route path=":itemId/edit" element={<AddEditItem />} />
          <Route path=":itemId/delete" element={<DeleteItem />} />
        </Route>

        <Route path={"schemas/:idOrKey/*"} element={<ViewSchema appConfig={appConfig} />} />
        <Route path="*" element={<Navigate to={"schemas"} replace />} />
      </Routes>
      <Outlet />
    </>
  );
};

export default SchemaAdminRoutes;
